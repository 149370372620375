function track(el, category) {
  window.ga("send", "event", category, "click", el.dataset.gaLabel)
}

export function initTracking() {
  document.body.addEventListener("click", (e) => {
    let t = e.target,
      el
    if ((el = t.closest("a.card-email"))) {
      track(el, "mail_team")
    } else if ((el = t.closest(".team-detail .contact"))) {
      track(el, "mail_team_detail")
    } else if ((el = t.closest(".detail-data .contact"))) {
      track(el, "mail_project")
    } else if ((el = t.closest(".detail-data .download-link"))) {
      track(el, "pdf_download")
    }
  })
}
