let $ = require("jquery")

export function initSearchBox() {
  $(".searchbox .searchbox-icon").click(() => {
    $(".searchbox .searchbox-input").toggle(200, function () {
      if ($(this).is(":visible")) {
        $(this).find("input[type=text]").focus()
      }
    })
  })
}
