/* global $ */

export class Navigation {
  constructor() {
    $(document.body).delegate("[data-toggle-mobile-nav]", "click", (el) => {
      el.preventDefault()
      this.el = el
      this.target =
        el.target && this.el.target.closest("[data-toggle-mobile-nav]")
      if (this.target) {
        document.documentElement.classList.toggle("nav-is-open")
        if (document.documentElement.classList.contains("nav-is-open")) {
          window.addEventListener("resize", () => {
            document.documentElement.classList.remove("nav-is-open")
          })
        }
      }
    })

    $(document.body).delegate("[data-toggle-mobile-nav-sub]", "click", (el) => {
      this.el = el
      this.target =
        el.target && this.el.target.closest("[data-toggle-mobile-nav-sub]")
      this.target.classList.toggle("rotate")
      if (
        this.target &&
        document.documentElement.classList.contains("nav-is-open")
      ) {
        this.nextElementSibling = this.target.nextElementSibling
        this.nextElementSibling.classList.toggle("is--open")
      }
    })
  }
}
