import { h, render } from "preact"
import { useState, useEffect } from "preact/hooks"
const { gettext, interpolate } = window // eslint-disable-line

let _T_result = gettext("Suche")
let _T_searchhelper = gettext("Ergebnisse für")
let _T_more = gettext("Mehr Ergebnisse laden")
let _T_no_results = gettext("Keine Ergebnisse gefunden.")
let _T_searching = gettext("Suche läuft...")

const TEMPLATE =
  "https://www.googleapis.com/customsearch/v1?key=KEY&cx=CX&q=QUERY&start=STARTINDEX&callback=CALLBACK"
const NO_QUERY = 1,
  DO_SEARCH = 2,
  SEARCHING = 3,
  SUCCESS = 4

export function renderSearch(el) {
  render(<Search cseCx={el.dataset.cseCx} cseKey={el.dataset.cseKey} />, el)
}

const loadResults = ({ cseCx, cseKey, query, startIndex }) => {
  window.history.replaceState({}, "", `?q=${query}`)

  const script = document.createElement("script")
  script.setAttribute("data-cse", "true")
  script.setAttribute(
    "src",
    TEMPLATE.replace(/KEY/, cseKey)
      .replace(/CX/, cseCx)
      .replace(/QUERY/, encodeURIComponent(query))
      .replace(/CALLBACK/, "cseHandler")
      .replace(/STARTINDEX/, startIndex),
  )
  document
    .querySelectorAll("script[data-cse]")
    .forEach((el) => el.parentNode.removeChild(el))
  document.body.appendChild(script)
}

const initialQuery = (() => {
  const url = new URL(document.location)
  return url.searchParams.get("q") || ""
})()

function Search({ cseCx, cseKey }) {
  const [query, setQuery] = useState(initialQuery)
  const [startIndex, setStartIndex] = useState(1)
  const [resultItems, setResultItems] = useState([])
  const [response, setResponse] = useState(null)
  const [currentState, setCurrentState] = useState(
    initialQuery ? DO_SEARCH : NO_QUERY,
  )

  useEffect(() => {
    window.cseHandler = (data) => {
      setCurrentState(SUCCESS)
      setResponse(data)
      if (data.items) {
        setResultItems([...resultItems, ...data.items])
      }
    }
    return () => {
      window.cseHandler = null
    }
  })

  useEffect(() => {
    // Actually load those results
    if (currentState === DO_SEARCH) {
      setCurrentState(SEARCHING)
      loadResults({ cseCx, cseKey, query, startIndex })
    }
  }, [currentState, startIndex]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <form
        method="get"
        onSubmit={(e) => {
          e.preventDefault()
          setCurrentState(DO_SEARCH)
          setStartIndex(1)
          setResultItems([])
        }}
      >
        <div className="criteria-filter">
          <div className="row cbox-row">
            <div className="small-12 medium-6 columns clearfix">
              <input
                className="search-field"
                type="text"
                name="q"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                id="id_q"
              />
            </div>

            <div className="small-4 medium-2 end columns">
              <button className="button" type="submit">
                {_T_result}
              </button>
            </div>
            <div className="small-12 medium-12 columns">
              {query ? (
                <p className="search-result__helper">
                  {_T_searchhelper} <span className="highlighted">{query}</span>
                  :
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </form>

      {currentState === SUCCESS || resultItems.length ? (
        <ResultList
          resultItems={resultItems}
          hasNextPage={!!(response.queries && response.queries.nextPage)}
          onNextPage={() => {
            setCurrentState(DO_SEARCH)
            setStartIndex(response.queries.nextPage[0].startIndex)
          }}
        />
      ) : null}
      {currentState === SEARCHING ? (
        <div className="row cbox-row">
          <div className="small-12 columns">
            <h2>{_T_searching}</h2>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const ResultList = ({ resultItems, hasNextPage, onNextPage }) => {
  if (resultItems.length) {
    return (
      <section className="cbox search-results">
        <div className="search-results">
          {resultItems.map((item, index) => (
            <article className="zebra" key={index}>
              <ResultItem {...item} />
            </article>
          ))}

          <br />

          {hasNextPage ? (
            <div className="row cbox-row">
              <div className="small-12 columns">
                <button
                  type="button"
                  className="button button--center"
                  onClick={onNextPage}
                >
                  {_T_more}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    )
  }
  return (
    <div className="row cbox-row">
      <div className="small-12 columns">
        <h2>{_T_no_results}</h2>
      </div>
    </div>
  )
}

const ResultItem = (props) => {
  let thumbnailSrc
  let mimePdf
  let sharingImage = "http://www.infras.ch/static/app/images/infras-share.png"
  let sharingImageEncrypted =
    "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSn8XoMF82p_KkKfFwTLTVR62mMALYjk4RiHe_5dDdBNNFCsGlZLkd7wGhq"
  try {
    thumbnailSrc = props.pagemap.metatags[0]["og:image"]
    if (thumbnailSrc === sharingImage) {
      thumbnailSrc = props.pagemap.cse_thumbnail[0].src
    }
  } catch (e) {
    if (!props.pagemap) {
    } else if (!props.pagemap.cse_image) {
    } else {
      thumbnailSrc = props.pagemap.cse_image[0].src
    }
  }
  mimePdf = props.mime
  let mimeTypePdf = "application/pdf"
  if (thumbnailSrc === sharingImage || thumbnailSrc === sharingImageEncrypted) {
    thumbnailSrc = false
  }
  return (
    <div className="row cbox-row">
      {mimePdf == mimeTypePdf ? (
        <div className="small-12 medium-3 columns">
          <div className="pdf-icon" />
        </div>
      ) : (
        thumbnailSrc && (
          <div className="small-12 medium-3 columns">
            <img src={thumbnailSrc} alt="" />
          </div>
        )
      )}
      <div className="small-12 medium-9 columns">
        <a
          className="search-result__title"
          href={props.link}
          dangerouslySetInnerHTML={{ __html: props.htmlTitle }}
        />
        <p className="search-result__content">{props.snippet}</p>
        <small style="text-decoration:underline">
          <a href={props.link}>{props.link}</a>
        </small>
      </div>
    </div>
  )
}
