!(function (Foundation, $) {
  // Elements with [data-open] will reveal a plugin that supports it when clicked.
  $(document).on("click.zf.trigger", "[data-open]", function () {
    let id = $(this).data("open")
    $(`#${id}`).triggerHandler("open.zf.trigger", [$(this)])
  })

  // Elements with [data-close] will close a plugin that supports it when clicked.
  // If used without a value on [data-close], the event will bubble, allowing it to close a parent component.
  $(document).on("click.zf.trigger", "[data-close]", function () {
    let id = $(this).data("close")
    if (id) {
      $(`#${id}`).triggerHandler("close.zf.trigger", [$(this)])
    } else {
      $(this).trigger("close.zf.trigger")
    }
  })

  // Elements with [data-toggle] will toggle a plugin that supports it when clicked.
  $(document).on("click.zf.trigger", "[data-toggle]", function () {
    let id = $(this).data("toggle")
    $(`#${id}`).triggerHandler("toggle.zf.trigger", [$(this)])
  })

  // Elements with [data-closable] will respond to close.zf.trigger events.
  $(document).on("close.zf.trigger", "[data-closable]", function () {
    let animation = $(this).data("closable") || "fade-out"
    if (Foundation.Motion) {
      Foundation.Motion.animateOut($(this), animation, function () {
        $(this).trigger("closed.zf")
      })
    } else {
      $(this).fadeOut().trigger("closed.zf")
    }
  })

  let MutationObserver = (function () {
    let prefixes = ["WebKit", "Moz", "O", "Ms", ""]
    for (let i = 0; i < prefixes.length; i++) {
      if (`${prefixes[i]}MutationObserver` in window) {
        return window[`${prefixes[i]}MutationObserver`]
      }
    }
    return false
  })()

  let checkListeners = function () {
    eventsListener()
    resizeListener()
    scrollListener()
    closemeListener()
  }
  /**
   * Fires once after all other scripts have loaded
   * @function
   * @private
   */
  $(window).on("load", () => {
    checkListeners()
  })

  //******** only fires this function once on load, if there's something to watch ********
  var closemeListener = function (pluginName) {
    let yetiBoxes = $("[data-yeti-box]"),
      plugNames = ["dropdown", "tooltip", "reveal"]

    if (pluginName) {
      if (typeof pluginName === "string") {
        plugNames.push(pluginName)
      } else if (
        typeof pluginName === "object" &&
        typeof pluginName[0] === "string"
      ) {
        plugNames.concat(pluginName)
      } else {
        window.console.error("Plugin names must be strings")
      }
    }
    if (yetiBoxes.length) {
      let listeners = plugNames
        .map((name) => {
          return `closeme.zf.${name}`
        })
        .join(" ")

      $(window)
        .off(listeners)
        .on(listeners, (e, pluginId) => {
          let plugin = e.namespace.split(".")[0]
          let plugins = $(`[data-${plugin}]`).not(
            `[data-yeti-box="${pluginId}"]`,
          )

          plugins.each(function () {
            let _this = $(this)

            _this.triggerHandler("close.zf.trigger", [_this])
          })
        })
    }
  }
  var resizeListener = function (debounce) {
    let timer,
      $nodes = $("[data-resize]")
    if ($nodes.length) {
      $(window)
        .off("resize.zf.trigger")
        .on("resize.zf.trigger", (_e) => {
          if (timer) {
            clearTimeout(timer)
          }

          timer = setTimeout(() => {
            if (!MutationObserver) {
              //fallback for IE 9
              $nodes.each(function () {
                $(this).triggerHandler("resizeme.zf.trigger")
              })
            }
            //trigger all listening elements and signal a resize event
            $nodes.attr("data-events", "resize")
          }, debounce || 10) //default time to emit resize event
        })
    }
  }
  var scrollListener = function (debounce) {
    let timer,
      $nodes = $("[data-scroll]")
    if ($nodes.length) {
      $(window)
        .off("scroll.zf.trigger")
        .on("scroll.zf.trigger", (_e) => {
          if (timer) {
            clearTimeout(timer)
          }

          timer = setTimeout(() => {
            if (!MutationObserver) {
              //fallback for IE 9
              $nodes.each(function () {
                $(this).triggerHandler("scrollme.zf.trigger")
              })
            }
            //trigger all listening elements and signal a scroll event
            $nodes.attr("data-events", "scroll")
          }, debounce || 10) //default time to emit scroll event
        })
    }
  }
  // function domMutationObserver(debounce) {
  //   // !!! This is coming soon and needs more work; not active  !!! //
  //   var timer,
  //   nodes = document.querySelectorAll('[data-mutate]');
  //   //
  //   if (nodes.length) {
  //     // var MutationObserver = (function () {
  //     //   var prefixes = ['WebKit', 'Moz', 'O', 'Ms', ''];
  //     //   for (var i=0; i < prefixes.length; i++) {
  //     //     if (prefixes[i] + 'MutationObserver' in window) {
  //     //       return window[prefixes[i] + 'MutationObserver'];
  //     //     }
  //     //   }
  //     //   return false;
  //     // }());
  //
  //
  //     //for the body, we need to listen for all changes effecting the style and class attributes
  //     var bodyObserver = new MutationObserver(bodyMutation);
  //     bodyObserver.observe(document.body, { attributes: true, childList: true, characterData: false, subtree:true, attributeFilter:["style", "class"]});
  //
  //
  //     //body callback
  //     function bodyMutation(mutate) {
  //       //trigger all listening elements and signal a mutation event
  //       if (timer) { clearTimeout(timer); }
  //
  //       timer = setTimeout(function() {
  //         bodyObserver.disconnect();
  //         $('[data-mutate]').attr('data-events',"mutate");
  //       }, debounce || 150);
  //     }
  //   }
  // }
  var eventsListener = function () {
    if (!MutationObserver) {
      return false
    }
    let nodes = document.querySelectorAll(
      "[data-resize], [data-scroll], [data-mutate]",
    )

    //element callback
    let listeningElementsMutation = function (mutationRecordsList) {
      let $target = $(mutationRecordsList[0].target)
      //trigger the event handler for the element depending on type
      switch ($target.attr("data-events")) {
        case "resize":
          $target.triggerHandler("resizeme.zf.trigger", [$target])
          break

        case "scroll":
          $target.triggerHandler("scrollme.zf.trigger", [
            $target,
            window.pageYOffset,
          ])
          break

        // case "mutate" :
        // console.log('mutate', $target);
        // $target.triggerHandler('mutate.zf.trigger');
        //
        // //make sure we don't get stuck in an infinite loop from sloppy codeing
        // if ($target.index('[data-mutate]') == $("[data-mutate]").length-1) {
        //   domMutationObserver();
        // }
        // break;

        default:
          return false
        //nothing
      }
    }

    if (nodes.length) {
      //for each element that needs to listen for resizing, scrolling, (or coming soon mutation) add a single observer
      for (let i = 0; i <= nodes.length - 1; i++) {
        let elementObserver = new MutationObserver(listeningElementsMutation)
        elementObserver.observe(nodes[i], {
          attributes: true,
          childList: false,
          characterData: false,
          subtree: false,
          attributeFilter: ["data-events"],
        })
      }
    }
  }
  // ------------------------------------

  // [PH]
  // Foundation.CheckWatchers = checkWatchers;
  Foundation.IHearYou = checkListeners
  // Foundation.ISeeYou = scrollListener;
  // Foundation.IFeelYou = closemeListener;
})(Foundation, jQuery)
